.ritz .waffle a {
  color: inherit;
}
/* titulo departamento - reporta a  */
.ritz .waffle .s3 {
  background-color: #bfbfbf;
  text-align: center;
  font-weight: bold;
  color: #000000;
  font-family: 'docs-NTR', Arial;
  font-size: 12pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
/* parámetros  departamento - reporta a  */
.ritz .waffle .s4 {  
  background-color: #ffffff;
  text-align: center; /* Agregado para centrar el texto */
  color: #000000;
  font-family: 'docs-NTR', Arial;
  font-size: 10pt;
  vertical-align:middle;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
/* Responsabilidades */
.ritz .waffle .s20 {
  border-bottom: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: 'docs-NTR', Arial;
  font-size: 10pt;
  vertical-align: bottom;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
.twoRow{
  border: 2px SOLID #000000;
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: 'docs-NTR', Arial;
  font-size: 10pt;
  vertical-align: bottom;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
/* Objetivo del puesto  */
.ritz .waffle .s8 {
  /* border: 10px SOLID #0070c0; */
  /* border-right: 2px SOLID #000000; */
  background-color: #ffffff;
  text-align: left;
  color: #000000;
  font-family: 'Arial';
  font-size: 10pt;
  vertical-align: bottom;
  /* white-space: nowrap; */
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
/* lista */
.listaE{
  display: "inline-block";
  font-weight: bold;

}
/* titulo interaccion  */
.ritz .waffle .s19 {
  
  background-color: #bfbfbf;
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  -webkit-text-decoration-skip: none;
  text-decoration-skip-ink: none;
  color: #000000;
  font-family: 'docs-NTR', Arial;
  font-size: 15pt;
  vertical-align: middle;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
/* titulo educación  */
.ritz .waffle .s5 {
  
  background-color: #bfbfbf;
  text-align: center;
  font-weight: bold;
  color: #000000;
  font-family: 'docs-NTR', Arial;
  font-size: 15pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}

/* titulo interna externa  */
.ritz .waffle .s21 {
  
  background-color: #bfbfbf;
  text-align: center;
  font-weight: bold;
  color: #000000;
  font-family: 'docs-NTR', Arial;
  font-size: 11pt;
  vertical-align: middle;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
/* titulo puesto  */
.ritz .waffle .s2 {
  
  background-color: #bfbfbf;
  text-align: center;
  font-weight: bold;
  color: #000000;
  font-family: 'docs-NTR', Arial;
  font-size: 20pt;
  vertical-align: middle;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
/* perfil de puesto */
.ritz .waffle .s0 {
  /* border-bottom: 2px SOLID #000000; */
  /* border-right: 2px SOLID #000000; */
  background-color: #0070c0;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  font-family: 'docs-NTR', Arial;
  font-size: 20pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
/* f drh */
.ritz .waffle .s1 {

  background-color: #0070c0;
  text-align: center;
  color: #ffffff;
  font-family: 'Arial';
  font-size: 15pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}

/* titulo con para  */
.ritz .waffle .s22 {
  
  background-color: #bfbfbf;
  text-align: center;
  font-weight: bold;
  color: #000000;
  font-family: 'docs-NTR', Arial;
  font-size: 11pt;
  vertical-align: middle;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}




/* objetivo del puesto */
.ritz .waffle .s7 {
  /* //border-bottom: 15px SOLID #0070c0; */
  background-color: #bfbfbf;
  text-align: center;
  font-weight: bold;
  color: #000000;
  font-family: 'Arial';
  font-size: 15pt;
  vertical-align: middle;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}
/* titulo competencias */
.ritz .waffle .s9 {
  background-color: #bfbfbf;
  text-align: center;
  font-weight: bold;
  color: #000000;
  font-family: 'Arial';
  font-size: 15pt;
  vertical-align: bottom;
  white-space: nowrap;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}

/* texto informativo */
.ritz .waffle .s18 {
  
  background-color: #bfbfbf;
  text-align: center;
  font-weight: bold;
  color: #000000;
  font-family: 'docs-NTR', Arial;
  font-size: 10pt;
  vertical-align: middle;
  white-space: normal;
  overflow: hidden;
  word-wrap: break-word;
  direction: ltr;
  padding: 2px 3px 2px 3px;
}

.cellTable {
  border:  2px SOLID #000000;
  background-color: #ffffff;
  text-align: center;
  color: #000000;
  font-family: 'docs-docs-NTR', Arial;
  font-size: 10pt;
  vertical-align:top;
  /* white-space: nowrap; */
  direction: ltr;
  padding: 2px 2px 10px 3px;
}